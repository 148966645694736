import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';

import {useDocTitle} from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';

import RouteChangeHandler from './routeChangeHandler.js';

function App() {

  const aos_init = () => {
    AOS.init({
      once: true,
      duration: 1000,
      easing: 'ease-out-cubic',
    });
  }

  const handleRouteChange = (path) => {
    // Place your custom load event logic here
    aos_init();
  };



  useDocTitle("Greenbelt Innovations - Software and Consulting Solutions");

  return (
    <>
      <Router>
        {/* RouteChangeHandler will detect route changes and call handleRouteChange */}
        <RouteChangeHandler onRouteChange={handleRouteChange} />
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}


export default App;
