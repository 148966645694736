import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const RouteChangeHandler = ({ onRouteChange }) => {
  const location = useLocation();

  useEffect(() => {
    onRouteChange(location.pathname); // Trigger the handler on route change
  }, [location, onRouteChange]);

  return null; // This component does not render anything
};

export default RouteChangeHandler;