import React from 'react';

const Process = () => {

    return (
        <div id="process" className="py-12" >

         <section>
         <div className="my-4 py-4">

<h2 className="my-2 text-center text-3xl text-green-800  font-bold">Process</h2>

<h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-gray-600">We follow a comprehensive approach to software development.</h2>
</div>

<div></div>
<div className="m-auto m-top-0 max-w-6xl p-8 md:p-12 h-5/6 mx-auto">

<div className="flex flex-col lg:flex-row"   data-aos="zoom-in-down">


<div className="flex flex-col ">
<div className="lg:my-5">
            <h3 className="text-3xl text-green-800 font-bold">
                1. <span className='font-black'>Analysis</span>
            </h3>
            <div>
                <p className='my-3 text-xl text-left text-gray-600 font-semibold'>
                We begin by understanding your goals, target audience, and unique requirements, collaborating with you to refine ideas and strategize the best approach.
                </p>
            </div>
        </div>
        </div>
</div>

<div className="flex flex-col lg:flex-row"   data-aos="zoom-in-down">
<div className="lg:my-5">
        <h3 className="text-3xl  text-green-800 font-bold">
            2. <span className='font-black'>Prototyping</span></h3>
        <div>
            <p className='my-3 text-xl text-left text-gray-600 font-semibold'>
            Creating intuitive prototypes that help visualize requirements and bring ideas to life..
            </p>
        </div>
    </div>


</div>

<div className="flex flex-col lg:flex-row"   data-aos="zoom-in-down">


<div className="lg:my-5">
        <h3 className="text-3xl  text-green-800 
    font-bold">
            3. <span className='font-black'>Development and Testing</span></h3>
        <div>
            <p className='my-3 text-xl text-left text-gray-600 font-semibold'>
            Using the latest technologies and frameworks, we ensure robust and scalable applications through agile development methodologies and rigorous testing.
            </p>
        </div>
    </div>

</div>

<div className="flex flex-col lg:flex-row"   data-aos="zoom-in-down">



<div className="lg:my-5">
        <h3 className="text-3xl  text-green-800 
    font-bold">
            4. <span className='font-black'>Deployment and Support</span></h3>
        <div>
            <p className='my-3 text-xl text-left text-gray-600 font-semibold'>
            Assisting in launching your application and providing ongoing support and maintenance for optimal performance post-launch.
            </p>
        </div>
    </div>
</div>

</div>
         </section>

               

        </div>
    )
}

export default Process;